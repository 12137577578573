import "./style.css"
import {Map, View} from "ol"
import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import {useGeographic} from "ol/proj"
import {WMTSCapabilities} from "ol/format"
import {optionsFromCapabilities as WMTS_optionsFromCapabilities} from "ol/source/WMTS"
import {ImageWMS, WMTS} from "ol/source"
import VectorTileLayer from "ol/layer/VectorTile"
import {applyStyle} from "ol-mapbox-style"
import {Attribution, defaults as defaultControls, FullScreen, ScaleLine} from "ol/control"
import ImageLayer from "ol/layer/Image"
import {register as registerProj4} from "ol/proj/proj4"
import proj4 from "proj4"

document.addEventListener("DOMContentLoaded", async () => {
  // für basemap external
  proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs") // https://epsg.io/25832
  registerProj4(proj4)

  useGeographic()

  const geoBasisAttribution = `© GeoBasis-DE / <a href="https://www.bkg.bund.de/" target="_blank">BKG</a> ${new Date().getFullYear()} <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>`

  const osmLayer = new TileLayer({
    source: new OSM(),
  })

  const wmsUrlGeoServer = "https://geotest-geoserver.batix.net/geoserver/Test/ows?service=WMS&version=1.1.1&request=GetCapabilities"

  const wmtsCapsUrl = "https://geotest-geoserver.batix.net/geoserver/Test/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities"
  const wmtsCapsResponse = await fetch(wmtsCapsUrl)
  const wmtsCapsGeoServer = new WMTSCapabilities().read(await wmtsCapsResponse.text())

  function makeWmsLayer(layerName, wmsUrl) {
    if (!wmsUrl) {
      wmsUrl = wmsUrlGeoServer
    }

    const wmsSource = new ImageWMS({
      url: wmsUrl,
      params: {
        LAYERS: layerName,
      },
      attributions: geoBasisAttribution,
      attributionsCollapsible: false,
    })

    return new ImageLayer({
      source: wmsSource,
    })
  }

  async function makeWmtsLayer(layerName, wmtsUrl) {
    let wmtsCaps = wmtsCapsGeoServer

    if (wmtsUrl) {
      const wmtsCapsResponse = await fetch(wmtsUrl)
      wmtsCaps = new WMTSCapabilities().read(await wmtsCapsResponse.text())
    }

    const wmtsOpts = WMTS_optionsFromCapabilities(wmtsCaps, {
      layer: layerName,
    })

    const wmtsSourceBasemapFarbe = new WMTS({
      ...wmtsOpts,
      attributions: geoBasisAttribution,
      attributionsCollapsible: false,
    })

    return new TileLayer({
      source: wmtsSourceBasemapFarbe,
    })
  }

  const wmsLayerBasemapFarbeExtern = makeWmsLayer("de_basemapde_web_raster_farbe", "https://sgx.geodatenzentrum.de/wms_basemapde")
  const wmtsLayerBasemapFarbeExtern = await makeWmtsLayer("de_basemapde_web_raster_farbe", "https://sgx.geodatenzentrum.de/wmts_basemapde/1.0.0/WMTSCapabilities.xml")

  const wmsLayerBasemapFarbe = makeWmsLayer("de_basemapde_web_raster_farbe")
  const wmtsLayerBasemapFarbe = await makeWmtsLayer("de_basemapde_web_raster_farbe")

  const wmsLayerVgLan = makeWmsLayer("vg5000_lan")
  const wmtsLayerVgLan = await makeWmtsLayer("vg5000_lan")

  const wmsLayerVgRbz = makeWmsLayer("vg5000_rbz")
  const wmtsLayerVgRbz = await makeWmtsLayer("vg5000_rbz")

  const wmsLayerVgKrs = makeWmsLayer("vg5000_krs")
  const wmtsLayerVgKrs = await makeWmtsLayer("vg5000_krs")

  const wmsLayerVgVwg = makeWmsLayer("vg5000_vwg")
  const wmtsLayerVgVwg = await makeWmtsLayer("vg5000_vwg")

  const wmsLayerVgGem = makeWmsLayer("vg5000_gem")
  const wmtsLayerVgGem = await makeWmtsLayer("vg5000_gem")

  const wmsLayerRelief = makeWmsLayer("dgm200_relief")
  const wmtsLayerRelief = await makeWmtsLayer("dgm200_relief")

  const wmsLayerSat = makeWmsLayer("sen2europe_rgb")
  const wmtsLayerSat = await makeWmtsLayer("sen2europe_rgb")

  const vectorLayer = new VectorTileLayer({
    declutter: true,
  })
  await applyStyle(vectorLayer, "https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_col.json")
  const vectorSource = vectorLayer.getSource()
  vectorSource.setAttributions(geoBasisAttribution)

  const attributionControl = new Attribution({
    collapsible: false,
  })

  const scaleLineControl = new ScaleLine({
    units: "metric",
  })

  const fullScreenControl = new FullScreen()

  const map = new Map({
    target: "map",

    view: new View({
      center: [11.36258599984344, 50.6486342312909],
      zoom: 20,
    }),

    controls: defaultControls({
      attribution: false,
    }).extend([attributionControl, scaleLineControl, fullScreenControl]),
  })

  map.on("loadstart", function () {
    map.getTargetElement().classList.add("spinner")
  })

  map.on("loadend", function () {
    map.getTargetElement().classList.remove("spinner")
  })


  const eleOsmShow = document.querySelector("#osm_show")
  const eleOsmTransparent = document.querySelector("#osm_transparent")

  const eleBasemapVectorShow = document.querySelector("#basemap_vector_show")
  const eleBasemapVectorTransparent = document.querySelector("#basemap_vector_transparent")

  const eleBasemapExternalShow = document.querySelector("#basemap_external_raster_show")
  const eleBasemapExternalTransparent = document.querySelector("#basemap_external_raster_transparent")
  const eleBasemapExternalProtoWms = document.querySelector("#basemap_external_raster_proto_wms")
  const eleBasemapExternalProtoWmts = document.querySelector("#basemap_external_raster_proto_wmts")

  const eleBasemapRasterShow = document.querySelector("#basemap_raster_show")
  const eleBasemapRasterTransparent = document.querySelector("#basemap_raster_transparent")
  const eleBasemapRasterProtoWms = document.querySelector("#basemap_raster_proto_wms")
  const eleBasemapRasterProtoWmts = document.querySelector("#basemap_raster_proto_wmts")

  const eleVgLanShow = document.querySelector("#vg_lan_show")
  const eleVgLanProtoWms = document.querySelector("#vg_lan_proto_wms")
  const eleVgLanProtoWmts = document.querySelector("#vg_lan_proto_wmts")

  const eleVgRbzShow = document.querySelector("#vg_rbz_show")
  const eleVgRbzProtoWms = document.querySelector("#vg_rbz_proto_wms")
  const eleVgRbzProtoWmts = document.querySelector("#vg_rbz_proto_wmts")

  const eleVgKrsShow = document.querySelector("#vg_krs_show")
  const eleVgKrsProtoWms = document.querySelector("#vg_krs_proto_wms")
  const eleVgKrsProtoWmts = document.querySelector("#vg_krs_proto_wmts")

  const eleVgVwgShow = document.querySelector("#vg_vwg_show")
  const eleVgVwgProtoWms = document.querySelector("#vg_vwg_proto_wms")
  const eleVgVwgProtoWmts = document.querySelector("#vg_vwg_proto_wmts")

  const eleVgGemShow = document.querySelector("#vg_gem_show")
  const eleVgGemProtoWms = document.querySelector("#vg_gem_proto_wms")
  const eleVgGemProtoWmts = document.querySelector("#vg_gem_proto_wmts")

  const eleReliefShow = document.querySelector("#relief_show")
  const eleReliefTransparent = document.querySelector("#relief_transparent")
  const eleReliefProtoWms = document.querySelector("#relief_proto_wms")
  const eleReliefProtoWmts = document.querySelector("#relief_proto_wmts")

  const eleSatShow = document.querySelector("#sat_show")
  const eleSatTransparent = document.querySelector("#sat_transparent")
  const eleSatProtoWms = document.querySelector("#sat_proto_wms")
  const eleSatProtoWmts = document.querySelector("#sat_proto_wmts")

  function updateLayers() {
    const transparency = 0.66
    const layers = []

    function maybeAddLayer(layer, doAdd, makeTransparent) {
      if (doAdd) {
        layers.push(layer)
      }

      layer.setOpacity(makeTransparent ? transparency : 1)
    }

    maybeAddLayer(osmLayer, eleOsmShow.checked, eleOsmTransparent.checked)
    maybeAddLayer(vectorLayer, eleBasemapVectorShow.checked, eleBasemapVectorTransparent.checked)

    maybeAddLayer(
      eleBasemapExternalProtoWms.checked ? wmsLayerBasemapFarbeExtern : wmtsLayerBasemapFarbeExtern,
      eleBasemapExternalShow.checked,
      eleBasemapExternalTransparent.checked,
    )

    maybeAddLayer(
      eleBasemapRasterProtoWms.checked ? wmsLayerBasemapFarbe : wmtsLayerBasemapFarbe,
      eleBasemapRasterShow.checked,
      eleBasemapRasterTransparent.checked,
    )

    maybeAddLayer(
      eleVgLanProtoWms.checked ? wmsLayerVgLan : wmtsLayerVgLan,
      eleVgLanShow.checked,
      false,
    )

    maybeAddLayer(
      eleVgRbzProtoWms.checked ? wmsLayerVgRbz : wmtsLayerVgRbz,
      eleVgRbzShow.checked,
      false,
    )

    maybeAddLayer(
      eleVgKrsProtoWms.checked ? wmsLayerVgKrs : wmtsLayerVgKrs,
      eleVgKrsShow.checked,
      false,
    )

    maybeAddLayer(
      eleVgVwgProtoWms.checked ? wmsLayerVgVwg : wmtsLayerVgVwg,
      eleVgVwgShow.checked,
      false,
    )

    maybeAddLayer(
      eleVgGemProtoWms.checked ? wmsLayerVgGem : wmtsLayerVgGem,
      eleVgGemShow.checked,
      false,
    )

    maybeAddLayer(
      eleReliefProtoWms.checked ? wmsLayerRelief : wmtsLayerRelief,
      eleReliefShow.checked,
      eleReliefTransparent.checked,
    )

    maybeAddLayer(
      eleSatProtoWms.checked ? wmsLayerSat : wmtsLayerSat,
      eleSatShow.checked,
      eleSatTransparent.checked,
    )

    map.setLayers(layers)
  }

  eleOsmShow.addEventListener("change", updateLayers)
  eleOsmTransparent.addEventListener("change", updateLayers)

  eleBasemapVectorShow.addEventListener("change", updateLayers)
  eleBasemapVectorTransparent.addEventListener("change", updateLayers)

  eleBasemapExternalShow.addEventListener("change", updateLayers)
  eleBasemapExternalTransparent.addEventListener("change", updateLayers)
  eleBasemapExternalProtoWms.addEventListener("change", updateLayers)
  eleBasemapExternalProtoWmts.addEventListener("change", updateLayers)

  eleBasemapRasterShow.addEventListener("change", updateLayers)
  eleBasemapRasterTransparent.addEventListener("change", updateLayers)
  eleBasemapRasterProtoWms.addEventListener("change", updateLayers)
  eleBasemapRasterProtoWmts.addEventListener("change", updateLayers)

  eleVgLanShow.addEventListener("change", updateLayers)
  eleVgLanProtoWms.addEventListener("change", updateLayers)
  eleVgLanProtoWmts.addEventListener("change", updateLayers)

  eleVgRbzShow.addEventListener("change", updateLayers)
  eleVgRbzProtoWms.addEventListener("change", updateLayers)
  eleVgRbzProtoWmts.addEventListener("change", updateLayers)

  eleVgKrsShow.addEventListener("change", updateLayers)
  eleVgKrsProtoWms.addEventListener("change", updateLayers)
  eleVgKrsProtoWmts.addEventListener("change", updateLayers)

  eleVgVwgShow.addEventListener("change", updateLayers)
  eleVgVwgProtoWms.addEventListener("change", updateLayers)
  eleVgVwgProtoWmts.addEventListener("change", updateLayers)

  eleVgGemShow.addEventListener("change", updateLayers)
  eleVgGemProtoWms.addEventListener("change", updateLayers)
  eleVgGemProtoWmts.addEventListener("change", updateLayers)

  eleReliefShow.addEventListener("change", updateLayers)
  eleReliefTransparent.addEventListener("change", updateLayers)
  eleReliefProtoWms.addEventListener("change", updateLayers)
  eleReliefProtoWmts.addEventListener("change", updateLayers)

  eleSatShow.addEventListener("change", updateLayers)
  eleSatTransparent.addEventListener("change", updateLayers)
  eleSatProtoWms.addEventListener("change", updateLayers)
  eleSatProtoWmts.addEventListener("change", updateLayers)

  updateLayers()
})
